import { Button } from "@nextui-org/button";
import { Card, CardBody, CardHeader } from "@nextui-org/card";
import { Input } from "@nextui-org/input";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/modal";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/table";
import { useState } from "react";

export default function Invoices() {
  const invoices = [
    { id: 1, date: "2024-06-01", amount: "$29.99", status: "Paid" },
    { id: 2, date: "2024-05-01", amount: "$29.99", status: "Paid" },
    { id: 3, date: "2024-04-01", amount: "$29.99", status: "Paid" },
    { id: 4, date: "2024-08-01", amount: "$29.99", status: "Paid" },
    { id: 4, date: "2024-08-01", amount: "$29.99", status: "Failed" },
  ];



  return (
    <div className="container mx-auto px-2 pb-28">
      <h1 className="text-3xl font-bold mb-8">Billing & Invoices</h1>
      <Card className="mb-8">
        <CardHeader>
          <h1>Current Billing Information</h1>
          <p>Your next billing date is July 1, 2025</p>
        </CardHeader>
        <CardBody>
          <p>
            <strong>Plan:</strong> Pro (Monthly)
          </p>
          <p>
            <strong>Amount:</strong> $29.99/month
          </p>
          <p>
            <strong>Payment Method:</strong> Visa ending in 1234
          </p>
          <Button className="mt-4">Update Billing Information</Button>
        </CardBody>
      </Card>
      <Card>
        <CardHeader className=" flex flex-col gap-2 items-start">
          <h1>Invoice History</h1>
          <p>Download your past invoices</p>
        </CardHeader>
        <CardBody>
          <Table>
            <TableHeader>
              <TableColumn>Date</TableColumn>
              <TableColumn>Amount</TableColumn>
              <TableColumn>Status</TableColumn>
              <TableColumn>Action</TableColumn>
            </TableHeader>
            <TableBody>
              {invoices.map((invoice) => (
                <TableRow key={invoice.id}>
                  <TableCell>{invoice.date}</TableCell>
                  <TableCell>{invoice.amount}</TableCell>
                  <TableCell>{invoice.status}</TableCell>
                  <TableCell className=" flex gap-2">
                    <SendInvoiceModal />
                    <Button variant="bordered">Download</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}

// create modal for send invoice to email
function SendInvoiceModal() {
  const { isOpen, onOpenChange, onClose, onOpen } = useDisclosure();

  const [email, setEmail] = useState("");

  function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    // This function would typically send the invoice to the email address
    console.log(email);
    onClose();
  }

  return (
    <>
      <Button 
        onClick={onOpen}
      variant="bordered">Send Email</Button>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          <ModalHeader className=" flex flex-col gap-2">
            <h2>Send Invoice to Email</h2>
          </ModalHeader>
          <form onSubmit={handleSubmit}>
            <ModalBody className="sm:max-w-[425px]">
              <div className="grid gap-4 py-4">
                <div className="flex gap-4 items-center">
                  <label className="text-right" htmlFor="email">
                    Email
                  </label>
                  <Input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}

                    required
                    variant="bordered"
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button type="submit" disabled={!email}>
                Send
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
