import { Button } from "@nextui-org/button";
import { Card, CardBody } from "@nextui-org/card";
import { Input } from "@nextui-org/input";
import { Modal, ModalContent, ModalHeader, useDisclosure } from "@nextui-org/modal";
import { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/table";

import { useToast } from "./ui/use-toast";
import { ChannelProvider, SessionContent } from "@/proivder/channelProvider";
import useAppStore from "@/store/store";
import { copyToClipboard } from "@/helpers/helpers";

interface ExportSourceProps {
  channel_id: number;
  onClose?: () => void;
}

export default function ExportSource({
  channel_id,
  onClose,
}: ExportSourceProps) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const { toast } = useToast();

  const [loading, setLoading] = useState(false);

  const [url, setUrl] = useState("");
  const [showExportUrls, setShowExportUrls] = useState(false);

  const [sessions, setSessions] = useState<SessionContent[]>([]);
  const channels = useAppStore((state) => state.channels);

  const handleShowExportUrls = () => {
    setShowExportUrls(true);
  };

  // This is a mock function to generate export URLs
  // In a real application, this might come from an API or be calculated based on the input URL

  function handleCopyURL() {
    copyToClipboard(url).then(() => {
      toast({
        title: "Copied!",
        description: "The URL has been copied to your clipboard.",
      });
    });
  }

  function timestampToDate(timestamp: number) {
    const date = new Date(timestamp *1000);
    return date.toLocaleString();
  }

  async function closeSession(sessionId: string) {
    await ChannelProvider.closeSession(sessionId);
    getSessions();
  }

  async function getSessions() {
    const _sessions = await ChannelProvider.getSessions();
    setSessions(_sessions);
  }

  async function handleCreateSession(channelId: number) {
    setLoading(true);
    const url = await ChannelProvider.openSession(channelId, false);
    setUrl(url);
    setLoading(false);

    copyToClipboard(url).then(() => {
      toast({
        title: "Stream URL",
        description: "Public Stream URL copied to clipboard",
        duration: 2000,
      });
    });

    getSessions();
  }

  async function handleCreateURL(_id: string) {
    const result = ChannelProvider.getLiveStream(_id);
    setUrl(result);
    setShowExportUrls(false);
    copyToClipboard(result).then(() => {
      toast({
        title: "Copied!",
        description: "The URL has been copied to your clipboard.",
      });
    });
  }

  useEffect(() => {
    if (!isOpen && channel_id) {
      if (channel_id) {
        setUrl("");
        handleCreateSession(channel_id);
        setShowExportUrls(false);
      }
      onOpen();
      getSessions();
    }
  }, [channel_id]);

  return (
    <Modal
      onClose={() => {
        onClose?.();
      }}
      isOpen={isOpen}
      size="xl"
      onOpenChange={onOpenChange}
    >
      <ModalContent>
      <ModalHeader className="flex flex-col gap-1">Stream Output</ModalHeader>
        <Card className="w-full min-w-80 mx-auto">
          <CardBody className="space-y-4">
            {!showExportUrls && (
              <>
                <div className="space-y-2">
                  <Input
                    id="url-input"
                    type="url"
                    placeholder=""
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    readOnly
                  />
                </div>
                {!loading ? (
                  <div className="flex gap-3">
                    <Button size="sm" onClick={handleShowExportUrls}>
                      Show Export URLs
                    </Button>
                    <Button color="primary" size="sm" onClick={handleCopyURL}>
                      {" "}
                      Copy URL{" "}
                    </Button>
                  </div>
                ) : (
                  <div className="flex">
                    <Button isLoading>Loading...</Button>
                  </div>
                )}
              </>
            )}
            {showExportUrls && (
              <div className="mt-4 space-y-2">
                <h3 className="text-lg font-semibold">Export Source URLs:</h3>
                {/* <ul className="list-disc pl-5 space-y-1">
                  {sessions.map((session, index) => (
                    <div key={session._id}>
                      <li key={index}>
                        <span>{session.payload.is_secure}</span>
                      </li>
                      <div className="flex gap-2">
                        {getExportUrls(session.url).map((exportUrl, idx) => (
                          <Button
                            key={idx}
                            size="sm"
                            variant="flat"
                            onClick={() => {
                              navigator.clipboard
                                .writeText(exportUrl)
                                .then(() => {
                                  toast({
                                    title: "Copied!",
                                    description:
                                      "The export URL has been copied to your clipboard.",
                                  });
                                });
                            }}
                          >
                            {exportUrl}
                          </Button>
                        ))}
                      </div>
                    </div>
                  ))}
                </ul> */}

                <Table aria-label="Example static collection table"
                 classNames={{
                    base: "max-h-[450px] overflow-y-auto overflow-x-auto",
                    table: "min-h-[400px]",
                  }}
                >
                  <TableHeader>
                    <TableColumn>NAME</TableColumn>
                    <TableColumn>Exp Date</TableColumn>
                    <TableColumn>Type</TableColumn>
                    <TableColumn>Action</TableColumn>
                  </TableHeader>
                  <TableBody >
                    {sessions.map((session) => (
                      <TableRow key={session._id}>
                        <TableCell>
                          {
                            channels.find(
                              (item) => item._id === session.payload.source_id
                            )?.name
                          }
                        </TableCell>

                        <TableCell>{session.payload.is_secure ? timestampToDate(session.payload.exp) : "_"}</TableCell>
                        <TableCell>
                          {session.payload.is_secure ? "Private" : "Public"}
                        </TableCell>

                        <TableCell>
                          <div className="flex gap-2">
                            <Button
                              onClick={() => handleCreateURL(session._id)}
                              size="sm"
                            >
                              {" "}
                              Copy{" "}
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              onClick={() => closeSession(session._id)}
                            >
                              {" "}
                              Close Session{" "}
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </div>
            )}
          </CardBody>
        </Card>
      </ModalContent>
    </Modal>
  );
}
