import React, { useEffect, useState } from "react";
import CreateSource from "./createSource";
import { Channel, ChannelProvider } from "@/proivder/channelProvider";
import { useLocation } from "react-router-dom";

import { useToast } from "./ui/use-toast";
import CreateOutput from "./createOutput";
import ChannelModal from "./channelList";

import useAppStore from "@/store/store";
import TranslationLanguageSelector from "./translationLanguageSelector";

const BottomNavBar = () => {
  const { toast } = useToast();

  const [, setIsOpen] = useState(false);

  const [updateSource, setUpdateSource] = useState<Channel | null>(null);

  function handleUpdateSource(channel: Channel | null) {
    setUpdateSource(channel);
  }

  const location = useLocation();
  const currentPath = location.pathname;

  const channelTranslations = useAppStore((v) => v.channelTranslations);
  const setChannelTranslations = useAppStore((v) => v.setChannelTranslations);
  const userId = useAppStore((v) => v.userId);

  const [isVideoOwner, setIsVideoOwner] = useState(false);

  const channel = useAppStore((v) => v.channel);

  const userRoles = useAppStore((v) => v.userRoles);

  const updateChannel = useAppStore((state) => state.updateChannel);

  const isLoggedIn = useAppStore((v) => v.isLoggedin);

  const isMobile = window.innerWidth <= 640;

  const reloadVideo = useAppStore((state) => state.reloadVideo);

  async function checkAndUpdateModel(lang: string) {
    const modelKey = `${channel?.audio[0].lang}_${lang}`;
    const reverseModelKey = `${lang}_${channel?.audio[0].lang}`;
    const models = await ChannelProvider.getTranslateModels();
    // console.log(res);
    const model = models.find((item) => item.key === modelKey);
    const reverseModel = models.find((item) => item.key === reverseModelKey);

    if (model && !model.load) {
      //  toast
      // ChannelProvider.updateTranslateModels({ ...model, load: true });
      toast({
        title: "Translation Model",
        description: ` ${model.model_name.toLocaleUpperCase()} Model is not loaded yet`,
      });
    }

    if (reverseModel && !reverseModel.load) {
      //ChannelProvider.updateTranslateModels({ ...reverseModel, load: true });
      toast({
        title: "Translation Model",
        description: ` ${reverseModel.model_name.toLocaleUpperCase()} Model is not loaded yet`,
      });
    }
  }

  useEffect(() => {
    if (channel) {
      //  setChannelTranslations(channel.translations);
    }
  }, [channel?.translations]);

  useEffect(() => {
    if (channel) {
      setIsVideoOwner(channel.owner === userId);
    }
  }, [channel?.owner, userId]);

  return (
    currentPath === "/" && (
      <div
        className={
          "fixed bottom-0 w-full bg-background border-t border-foreground-200 shadow-lg  lg:hidden z-50 dark:bg-opacity-70 "
        }
      >
        <div className="flex justify-between">
          {isMobile && (
            <CreateSource
              editItem={updateSource}
              onCreated={() => setIsOpen(false)}
              onUpdate={() => handleUpdateSource(null)}
            />
          )}

          {/* <Dropdown
            onOpenChange={onOpenChange}
            isDisabled={
              !userRoles.includes("admin") &&
              (!isLoggedIn || !isVideoOwner)
            }
            isTriggerDisabled={!isLoggedIn || !isVideoOwner}
            isOpen={isOpen}
          >
            <DropdownTrigger>
              <a href="#0" className=" w-full">
                <NavItem
                  icon={<BookPlus className="w-5 h-5" />}
                  label="Language"
                  isActive={false}
                />
              </a>
            </DropdownTrigger>
            <DropdownMenu
              aria-label="subtitles"
              selectedKeys={channelTranslations}
              selectionMode="multiple"
              variant="flat"
              onSelectionChange={async (v) => {
                let translates = Array.from(v)
                  .filter((t) => t)
                  .map((t) => t.toString());

                if (
                  !userRoles.includes("admin") &&
                  !userRoles.includes("paid_user") &&
                  translates.length > 1
                ) {
                  translates = [translates[translates.length - 1]];
                }

                setChannelTranslations(translates);

                await updateChannel({
                  _id: channel!._id,
                  //  name: channel!.name,
                  //  url: channel!.url,
                  translations: translates,
                });

                toast({
                  title: "Translations",
                  description: "Translations has been updated",
                });

                checkAndUpdateModel(translates[translates.length - 1]);

                reloadVideo();
                //setChannel(channel!);
              }}
            >
              {langauges
                // .filter((item) => channel?.audio[0].lang !== item.code)
                .map((sub) => (
                  <DropdownItem key={sub.code} value={sub.code}>
                    {sub.language}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown> */}

          <TranslationLanguageSelector
            isAllow={userRoles.includes("admin") || isVideoOwner}
            disabled={
              !userRoles.includes("admin") && (!isLoggedIn || !isVideoOwner)
            }
            selectedLanguages={channelTranslations}
            onSelect={async (v) => {
              let translates = Array.from(v)
                .filter((t) => t)
                .map((t) => t.toString());

              if (
                !userRoles.includes("admin") &&
                !userRoles.includes("paid_user") &&
                translates.length > 1
              ) {
                translates = [translates[translates.length - 1]];
              }

              setChannelTranslations(translates);

              await updateChannel({
                _id: channel!._id,
                //  name: channel!.name,
                //  url: channel!.url,
                translations: translates,
              });

              toast({
                title: "Translations",
                description: "Translations has been updated",
              });

              checkAndUpdateModel(translates[translates.length - 1]);

              reloadVideo();
            }}
            mobile={true}
          />

          <CreateOutput />

          <ChannelModal />
        </div>
      </div>
    )
  );
};

interface NavItemProps {
  icon: React.ReactNode;
  label: string;
  isActive: boolean;
  onClick?: () => void;
  disabled?: boolean;
}

export const NavItem = ({
  icon,
  label,
  isActive,
  onClick,
  disabled,
}: NavItemProps) => (
  <button
    onClick={onClick}
    className={`flex flex-col items-center justify-center w-full py-2 ${disabled ? " text-foreground-300" : isActive ? " text-secondary-400" : " text-foreground-500"}`}
    disabled={disabled}
  >
    {icon}
    <span className="text-xs">{label}</span>
  </button>
);

export default BottomNavBar;
