import useAppStore, { AudioTrackItem } from "@/store/store";
import { Button } from "@nextui-org/button";
import { Tooltip } from "@nextui-org/tooltip";
import React, { useEffect } from "react";
import { ListVideo } from "lucide-react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/modal";

let userScrolling = false;
let scrollTimeout: any;

export default function VideoTimeline() {
  const videoPlayer = useAppStore((state) => state.videoPlayer);
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const timelineRef = React.useRef<HTMLDivElement>(null);

  const subtitleTracks = useAppStore((state) => state.currentSubtitles);
  const audioTracks = useAppStore((state) => state.audioTracks);

  const totalDuration =
    videoPlayer && videoPlayer?.buffered.length
      ? videoPlayer?.buffered?.end(0)
      : 0; // in seconds
  const pixelsPerSecond = 20;
  const markerInterval = 5; // in seconds

  const videoSegments = videoPlayer
    ? [
        {
          id: 1,
          start: 0,
          end: videoPlayer?.currentTime,
          title: "playing",
          main: true,
        },
        // buffering duration
        {
          id: 2,
          start: videoPlayer?.currentTime ? videoPlayer.currentTime : 0,
          end: videoPlayer?.buffered.length ? videoPlayer?.buffered?.end(0) : 0,
          title: "Video Buffering",
        },
      ]
    : [];

  // const subtitleSegments = [
  //     { id: 1, start: 10, end: 50, text: "Welcome to our video" },
  //   ];

  function goToTime(time: number) {
    if (videoPlayer) {
      videoPlayer.currentTime = time;
    }
  }

  interface SubtitleSegment {
    id: number;
    start: number;
    end: number;
    text: string;
  }

  const subtitleSegments: SubtitleSegment[] = [];

  subtitleTracks.forEach((track) => {
    track.subtitles?.forEach((subtitle) => {
      subtitleSegments.push({
        id: subtitle.start + subtitle.end,
        start: subtitle.start,
        end: subtitle.end,
        text: subtitle.text,
      });
    });
  });

  function donwloadAudioTrack(track: AudioTrackItem) {
    fetch(track.url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = track.name + "_" + track.id + ".ts";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
  }

  const formatTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    const pts = Math.floor((seconds % 1) * 1000); // Assuming pts is in milliseconds
    return `${hours}:${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}:${pts.toString().padStart(3, "0")}`;
  };

  useEffect(() => {
    const handleUserScroll = () => {
      userScrolling = true;
      clearTimeout(scrollTimeout);
      scrollTimeout = setTimeout(() => {
        userScrolling = false;
      }, 5000);
    };

    if (timelineRef.current) {
      timelineRef.current.addEventListener("scroll", handleUserScroll);

      const scrollLeft = (videoPlayer?.currentTime || 0) * pixelsPerSecond;
      if (
        !userScrolling &&
        Math.abs(timelineRef.current.scrollLeft - scrollLeft) >= 300
      ) {
        timelineRef.current.scrollTo({
          left: scrollLeft,
          behavior: "smooth",
        });
      }

      return () => {
        timelineRef.current?.removeEventListener("scroll", handleUserScroll);
      };
    }
  }, [videoPlayer?.currentTime]);

  return (
    <>
      <Tooltip content="Captions Timeline section">
        <Button
          isIconOnly
          className=" bg-opacity-40"
          size="sm"
          onClick={() => onOpen()}
        >
          <ListVideo />
        </Button>
      </Tooltip>
      <Modal
        size="5xl"
        className="h-96 max-h-96"
        placement="bottom"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        backdrop="transparent"
      >
        <ModalContent>
          <ModalHeader className="flex  gap-1 p-2 text-xs items-center">
            <span className=" text-medium"> Timeline</span>
            <div className="flex gap-2 ml-2">
              <div className="flex items-center gap-1">
                <div className="w-4 h-4 bg-blue-500 rounded-full" />
                <span>Video</span>
              </div>
              <div className="flex items-center gap-1">
                <div className="w-4 h-4 bg-green-700 rounded-full" />
                <span>Subtitle</span>
              </div>

              <div className="flex items-center gap-1">
                <div className="w-4 h-4 bg-yellow-700 rounded-full" />
                <span>Audio</span>
              </div>
            </div>
          </ModalHeader>
          <>
            {videoPlayer ? (
              <div
                ref={timelineRef}
                className="w-full bg-gray-900 p-4 rounded-lg shadow-lg relative overflow-x-scroll min-h-[300px] max-h-[300px] md:max-h-96"
              >
                {/* Tracks guide by color circle */}

                {/* Time ruler */}
                {/* Time ruler */}
                <div className="flex mb-2 relative">
                  <div
                    className="flex-shrink-0 relative"
                    style={{
                      width: `${totalDuration * pixelsPerSecond}px`,
                      height: "20px",
                    }}
                  >
                    {Array.from({
                      length: Math.ceil(totalDuration / markerInterval),
                    }).map((_, index) => (
                      <React.Fragment key={index}>
                        <div
                          className="absolute top-0 w-px h-full bg-gray-600"
                          style={{
                            left: `${index * markerInterval * pixelsPerSecond}px`,
                          }}
                        />
                        <div
                          className="absolute top-0 text-gray-400 text-xs"
                          style={{
                            left: `${index * markerInterval * pixelsPerSecond}px`,
                            transform: "translateX(-50%)",
                          }}
                        >
                          {formatTime(index * markerInterval)}
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
                {/* Video track */}
                <div className="mb-4 relative h-12 bg-gray-800">
                  <div
                    className="absolute inset-y-0 left-0 bg-blue-700 opacity-50"
                    style={{ width: `${totalDuration * pixelsPerSecond}px` }}
                  />
                  {videoSegments.map((segment) => (
                    <div
                      key={segment.id}
                      className={
                        "absolute inset-y-0 bg-blue-500 flex items-center px-2 text-xs rounded-sm text-white truncate border-l-1 border-blue-700" +
                        (segment.main ? " bg-blue-500" : " bg-blue-400")
                      }
                      style={{
                        left: `${segment.start * pixelsPerSecond}px`,
                        width: `${(segment.end - segment.start) * pixelsPerSecond}px`,
                      }}
                    >
                      {segment.title}
                    </div>
                  ))}
                </div>

                {/* Subtitle track */}

                {subtitleTracks.map((track, i) => (
                  <div
                    className="mb-4 relative h-8 bg-gray-800 min-w-full"
                    key={track.index}
                    style={{
                      width: `${totalDuration * pixelsPerSecond}px`,
                    }}
                  >
                    <span className=" absolute text-[9px] top-[8px] left-[-15px] font-semibold">
                      {" "}
                      {track.label ? track.label.toUpperCase(): ""}{" "}
                    </span>
                    {track.subtitles?.map((segment) => (
                      <Tooltip
                        key={segment.text + segment.start}
                        content={
                          <div className="px-1 py-2">
                            <div className="text-small font-bold">
                              {formatTime(segment.start)} -{" "}
                              {formatTime(segment.end)}
                            </div>
                            <div className="text-tiny">{segment.text}</div>
                          </div>
                        }
                      >
                        <Button
                          key={segment.text + segment.start}
                          onClick={() => {
                            goToTime(segment.start);
                          }}
                          className={
                            "absolute inset-y-0 bg-green-500 rounded-sm flex items-center px-2 text-xs cursor-pointer text-white truncate border-l-1 hover:bg-green-800 transition-all ease-linear duration-200 border-green-700" +
                            (i == 0 ? " bg-green-700" : " bg-green-500")
                          }
                          style={{
                            left: `${segment.start * pixelsPerSecond}px`,
                            width: `${(segment.end - segment.start) * pixelsPerSecond}px`,
                          }}
                        >
                          {segment.text}
                        </Button>
                      </Tooltip>
                    ))}
                  </div>
                ))}

                {/* Audio track */}
                {audioTracks.map((track) => (
                  <div
                    className="mb-4 relative h-8 bg-gray-800 min
                    -w-full"
                    key={track.id}
                    style={{
                      width: `${totalDuration * pixelsPerSecond}px`,
                    }}
                  >
                    <span className=" absolute text-[9px] top-[8px] left-[-15px] font-semibold">
                      {" "}
                      {track.name.toUpperCase()}{" "}
                    </span>
                    {track.items.map((segment, i) => (
                      <Tooltip
                        key={segment.id}
                        content={
                          <div className="px-1 py-2">
                            <div className="text-small font-bold">
                              {formatTime(segment.start)} -{" "}
                              {formatTime(segment.end)}
                            </div>
                            <div className="text-tiny">{segment.id}</div>
                          </div>
                        }
                      >
                        <Button
                          key={segment.name + segment.start}
                          onClick={() => {
                            goToTime(segment.start);
                            donwloadAudioTrack(segment);
                          }}
                          className={
                            "absolute inset-y-0 bg-yellow-600 rounded-sm flex items-center px-2 text-xs cursor-pointer text-white truncate border-l-1 hover:bg-yellow-900 transition-all ease-linear duration-200 border-yellow-900" +
                            (track.main ? " bg-yellow-700" : " bg-yellow-500")
                          }
                          style={{
                            left: `${segment.start * pixelsPerSecond}px`,
                            width: `${(segment.end - segment.start) * pixelsPerSecond}px`,
                          }}
                        >
                          {segment.name} - {i}
                        </Button>
                      </Tooltip>
                    ))}
                  </div>
                ))}

                <div
                  id="timeline-cursor"
                  className="absolute top-0 w-[1px] bg-white cursor-ew-resize z-10 h-full"
                  style={{
                    left: `${(videoPlayer.currentTime || 0) * pixelsPerSecond + 16}px`,
                    height: "calc(100% - 48px)", // Adjust height to cover all tracks
                    transform: "translateX(-50%)",
                    top: "48px",
                  }}
                  aria-label="Timeline cursor"
                  role="slider"
                  aria-valuemin={0}
                  aria-valuemax={totalDuration}
                  aria-valuenow={videoPlayer.currentTime}
                >
                  <div
                    style={{
                      transform: "translateX(-50%)",
                    }}
                    className=" p-1 text-xs bg-background bg-opacity-50 rounded-sm absolute top-[-15px]"
                  >
                    {formatTime(videoPlayer.currentTime)}
                  </div>
                </div>

                <div className="mt-4 text-gray-400 text-sm flex gap-3">
                  <span>
                    {" "}
                    Current Video Player: {formatTime(
                      videoPlayer.currentTime
                    )}{" "}
                  </span>

                  <span>
                    {" "}
                    Total Buffering Time: {formatTime(totalDuration)}
                  </span>
                </div>
              </div>
            ) : null}{" "}
          </>
        </ModalContent>
      </Modal>
    </>
  );
}
