import request from "./request";

const BASE_URL = "https://emsbt.com/";

export interface PlanLimit {
  max_recording_time: number;
  max_subtitle_languages: number;
  max_sources: number;
}

export interface Plan {
  _id: number;
  name: string;
  description: string;
  price: number;
  features: string[];
  limits: PlanLimit;
}

export interface calculateBody {
  plan_id: number;
  language: number;
  sources: number;
  recording_time: number;
}

export interface UserPlan {
  _id: string;
  user_id: string;
  plan_id: number;
  features: Record<string, any>;
  active: boolean;
  status: string;
}

export interface CheckoutBody {
  provider_id: number;
  price: number;
  user_id: string;
}

export interface CheckoutSession {
  checkout_url: "string";
  payment_id: "string";
}

export interface PaymentProvider {
  _id: number;
  provider_name: string;
  provider_code: string;
  enabled: boolean;
  api_key: string;
  success_url: string;
  cancel_url: string;
}

export interface PaymentResponse {
  status: boolean;
  message: string;
  request: string;
  extra: Record<string, any>;
}

export class PaymentProvider {
  static async getPaymentPlans() {
    try {
      const response = await request.get<{
        content: Plan[];
      }>("/config/plans");

      return response.data.content;
    } catch (error) {
      throw error;
    }
  }

  // /emsbt/price/calculate
  static async calculatePrice(data: calculateBody, plan: Plan) {
    try {
      console.log(data, plan);

      const _data: calculateBody = {
        plan_id: plan._id,
        language: data.language - plan.limits.max_subtitle_languages,
        sources: data.sources - plan.limits.max_sources,
        recording_time: data.recording_time - plan.limits.max_recording_time,
      };

      const response = await request.post<{ total_price: number }>(
        "/price/calculate",
        _data
      );

      return response.data.total_price;
    } catch (error) {
      throw error;
    }
  }

  // /emsbt/plan/select
  static async selectPlan(data: calculateBody, plan: Plan) {
    try {
      const _data = {
        plan_id: plan._id,

        features: {
          language: data.language - plan.limits.max_subtitle_languages,
          sources: data.sources - plan.limits.max_sources,
          recording_time: data.recording_time - plan.limits.max_recording_time,
        },
      };
      const response = await request.post("/plan/select", _data);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // /emsbt/user/plan
  static async getUserPlan() {
    try {
      const response = await request.get<{
        content: UserPlan[];
      }>("/user/plan");

      return response.data.content;
    } catch (error) {
      throw error;
    }
  }

  // /payment/create/checkout
  static async createCheckoutSession(body: CheckoutBody) {
    try {
      const response = await request.post<CheckoutSession>(
        "/payment/create/checkout",
        body,
        {
          baseURL: BASE_URL,
        }
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // /payment/config/providers
  static async getPaymentProviders() {
    try {
      const response = await request.get<{
        content: PaymentProvider[];
      }>("/payment/config/providers", {
        baseURL: BASE_URL,
      });

      return response.data.content;
    } catch (error) {
      throw error;
    }
  }

  // /payment/success
  static async handlePaymentSuccess(payment_id: string, session_id: string) {
    try {
      const response = await request.get<PaymentResponse>("/payment/success", {
        params: {
          payment_id,
          session_id,
        },

        baseURL: BASE_URL,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
