import { useState } from "react";
import { Modal, ModalHeader, ModalContent } from "@nextui-org/modal";
import { useDisclosure } from "@nextui-org/modal";
import { Input } from "@nextui-org/input";
import { VideoIcon } from "lucide-react";
import { NavItem } from "./bottomBar";

import StreamList from "./streamList";

import useAppStore from "@/store/store";
import { ScrollShadow } from "@nextui-org/scroll-shadow";

const ChannelModal = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const { onOpen, onOpenChange, isOpen, onClose } = useDisclosure();
  const channels = useAppStore((state) => state.channels);
  const channel = useAppStore((state) => state.channel);
  const setChannel = useAppStore((state) => state.setChannel);

  const player = useAppStore((state) => state.videoPlayer);

  const filteredChannels = channels.filter((channel) =>
    channel.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <NavItem
        icon={<VideoIcon className="w-5 h-5" />}
        label="Streams"
        onClick={() => onOpen()}
        isActive={false}
      />

      <Modal
        aria-labelledby="modal-title"
        backdrop="transparent"
        className=" min-h-[50vh] w-full rounded-sm py-1"
        isOpen={isOpen}
        onOpenChange={onOpenChange}
      >
        <ModalHeader>
          <h2 id="modal-title">Channels</h2>
        </ModalHeader>
        <ModalContent>
        <ModalHeader className="flex flex-col gap-1">
        <Input
            isClearable
            fullWidth
            color="default"
            size="md"
            variant="underlined"
            placeholder="Search by name"
            onChange={(e) => setSearchTerm(e.target.value)}
          />

        </ModalHeader>
         
          <div style={{ marginTop: "20px" }}>
            <ScrollShadow className=" max-h-96 overflow-scroll  w-full justify-center">
              <StreamList
                channel={channel!}
                items={filteredChannels.filter((c) =>
                  c.name.toLowerCase().includes(searchTerm.toLowerCase())
                )}
                videoPlayer={player!}
                onChannel={(c) => setChannel(c)}
                onDelete={() => null}
                onExport={() => null}
                onPlay={() => onClose()}
                onUpdate={() => null}
              />
            </ScrollShadow>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ChannelModal;
