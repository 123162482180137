"use client";

import { useState, useEffect } from "react";
import { Plus, Minus, ShoppingCart, X } from "lucide-react";

import { useToast } from "@/components/ui/use-toast";
import { Card, CardBody, CardFooter, CardHeader } from "@nextui-org/card";
import { Button } from "@nextui-org/button";
import { Divider } from "@nextui-org/divider";
import { Chip } from "@nextui-org/chip";
import { Slider } from "@nextui-org/slider";
import useAppStore from "@/store/store";
import { useNavigate } from "react-router-dom";

interface Product {
  id: string;
  name: string;
  credits: number;
}

export interface BasketItem extends Product {
  quantity: number;
}

const products: Product[] = [
  { id: "source", name: "Extra Source", credits: 5 },
  { id: "transcript", name: "Transcript Subtitle", credits: 5 },
  { id: "translation", name: "Translation Subtitle", credits: 10 },
  { id: "dubbing", name: "Dubbing", credits: 12 },
  { id: "output", name: "Output (public stream)", credits: 15 },
];

export default function CartSection() {
  const [basket, setBasket] = useState<BasketItem[]>([]);
  const [customCredits, setCustomCredits] = useState(0);
  const { toast } = useToast();

  const navigate = useNavigate();

  const setBasketItems = useAppStore((state) => state.setBasketItems);

  useEffect(() => {
    const savedBasket = localStorage.getItem("basket");

    if (savedBasket) {
      setBasket(JSON.parse(savedBasket));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("basket", JSON.stringify(basket));
    setBasketItems(basket);
  }, [basket]);

  const addToBasket = (product: Product) => {
    setBasket((prevBasket) => {
      const existingItem = prevBasket.find((item) => item.id === product.id);

      if (existingItem) {
        return prevBasket.map((item) =>
          item.id === product.id
            ? { ...item, quantity: item.quantity + 1 }
            : item
        );
      }

      return [...prevBasket, { ...product, quantity: 1 }];
    });

    toast({
      title: "Added to basket",
      description: `${product.name} has been added to your basket.`,
    });
  };

  const removeFromBasket = (productId: string) => {
    setBasket((prevBasket) =>
      prevBasket.filter((item) => item.id !== productId)
    );
    toast({
      title: "Removed from basket",
      description: "The item has been removed from your basket.",
      variant: "destructive",
    });
  };

  const updateQuantity = (productId: string, newQuantity: number) => {
    if (newQuantity < 1) return;
    setBasket((prevBasket) =>
      prevBasket.map((item) =>
        item.id === productId ? { ...item, quantity: newQuantity } : item
      )
    );
  };

  const addCustomCredits = () => {
    if (customCredits < 1) {
      toast({
        title: "Invalid credit amount",
        description: "Please select at least 1 credit.",
        variant: "destructive",
      });
      return;
    }
    setBasket((prevBasket) => [
      ...prevBasket,
      {
        id: "custom",
        name: "Custom Credits",
        credits: customCredits,
        quantity: 1,
      },
    ]);
    setCustomCredits(0);
    toast({
      title: "Custom credits added",
      description: `${customCredits} credits have been added to your basket.`,
    });
  };

  const totalCredits = basket.reduce(
    (sum, item) => sum + item.credits * item.quantity,
    0
  );

  return (
    <div className="grid gap-8 md:grid-cols-[1fr_1.5fr] md:[&>*:nth-child(2)]:order-first">
      <section>
        <h2 className="text-2xl font-semibold mb-4">Custom Credits</h2>
        <Card>
          <CardHeader className="flex flex-col gap-2 justify-start items-start">
            <h2 className="text-lg font-semibold">Add Custom Credits</h2>
          </CardHeader>
          <CardBody>
            <div className="space-y-2">
              <div className="flex justify-between text-sm">
                <span>0</span>
                <span>2500</span>
                <span>5000</span>
              </div>
              <Slider
                minValue={0}
                maxValue={5000}
                value={customCredits}
                onChange={(e) => setCustomCredits(parseInt(e.toString()))}
                className="w-full"
              />
              <div className="text-center font-semibold">
                {customCredits} credits
              </div>
            </div>
          </CardBody>
          <CardFooter>
            <Button onClick={addCustomCredits}>Add</Button>
          </CardFooter>
        </Card>

        <h2 className="text-2xl font-semibold mt-8 mb-4">Your Basket</h2>
        {basket.length === 0 ? (
          <p>Your basket is empty.</p>
        ) : (
          <Card>
            <CardBody className="pt-6">
              {basket.map((item) => (
                <div
                  key={item.id}
                  className="flex items-center justify-between mb-4"
                >
                  <div>
                    <p className="font-semibold">{item.name}</p>
                    <p className="text-sm text-gray-500">
                      {item.credits} credits each
                    </p>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Button
                      isIconOnly
                      variant="bordered"
                      onClick={() => updateQuantity(item.id, item.quantity - 1)}
                    >
                      <Minus className="h-4 w-4" />
                    </Button>
                    <span className="w-8 text-center">{item.quantity}</span>
                    <Button
                      isIconOnly
                      variant="bordered"
                      onClick={() => updateQuantity(item.id, item.quantity + 1)}
                    >
                      <Plus className="h-4 w-4" />
                    </Button>
                    <Button
                      isIconOnly
                      color="danger"
                      onClick={() => removeFromBasket(item.id)}
                    >
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
              ))}
              <Divider className="my-4" />
              <div className="flex justify-between items-center">
                <p className="font-semibold">Total Credits:</p>
                <p className="font-semibold">{totalCredits}</p>
              </div>
              <Divider className="my-4" />
              <div className="flex justify-between items-center">
                <p className="font-semibold">Total Price:</p>
                <p className="font-semibold">
                  ${(totalCredits * 1).toFixed(2)}
                </p>
              </div>
            </CardBody>
            <CardFooter className="flex justify-end">
              <Button
                color="primary"
                onPress={() => {
                  // navigate to checkout page
                  navigate("/checkout");
                }}
              >
                <ShoppingCart className="mr-2 h-4 w-4" />{" "}
                <span className=" text-md font-semibold">Checkout</span>
              </Button>
            </CardFooter>
          </Card>
        )}
      </section>

      <section>
        <h2 className="text-2xl font-semibold mb-4">Products</h2>
        <div className="grid max-md:grid-cols-2 gap-4">
          {products.map((product) => (
            <Card isFooterBlurred key={product.id}>
              <CardHeader className="flex flex-col gap-2 items-start justify-center">
                <h2 className="text-md font-semibold">{product.name}</h2>
                <Chip variant="dot" color="primary">
                  {product.credits} Credits{" "}
                  <span className=" text-xs ml-2 opacity-30"> X1 </span>
                </Chip>
              </CardHeader>
              <CardFooter>
                <Button
                  startContent={<ShoppingCart size={18} />}
                  size="sm"
                  onClick={() => addToBasket(product)}
                >
                  ADD
                </Button>
              </CardFooter>
            </Card>
          ))}
        </div>
      </section>
    </div>
  );
}
