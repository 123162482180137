import { Button } from "@nextui-org/button";
import { Card, CardBody, CardHeader } from "@nextui-org/card";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/table";

export default function BillingInvoices() {
  const invoices = [
    { id: 1, date: "2023-06-01", amount: "$29.99", status: "Paid" },
    { id: 2, date: "2023-05-01", amount: "$29.99", status: "Paid" },
    { id: 3, date: "2023-04-01", amount: "$29.99", status: "Paid" },
  ];

  return (
    <div className="container mx-auto px-4 py-8 pb-28">
      <h1 className="text-3xl font-bold mb-8">Billing & Invoices</h1>
      <Card className="mb-8">
        <CardHeader>
          <h1>Current Billing Information</h1>
          <p>Your next billing date is July 1, 2025</p>
        </CardHeader>
        <CardBody>
          <p>
            <strong>Plan:</strong> Pro (Monthly)
          </p>
          <p>
            <strong>Amount:</strong> $29.99/month
          </p>
          <p>
            <strong>Payment Method:</strong> Visa ending in 1234
          </p>
          <Button className="mt-4">Update Billing Information</Button>
        </CardBody>
      </Card>
      <Card>
        <CardHeader>
          <h1>Invoice History</h1>
          <p>Download your past invoices</p>
        </CardHeader>
        <CardBody>
          <Table>
            <TableHeader>
              <TableColumn>Date</TableColumn>
              <TableColumn>Amount</TableColumn>
              <TableColumn>Status</TableColumn>
              <TableColumn>Action</TableColumn>
            </TableHeader>
            <TableBody>
              {invoices.map((invoice) => (
                <TableRow key={invoice.id}>
                  <TableCell>{invoice.date}</TableCell>
                  <TableCell>{invoice.amount}</TableCell>
                  <TableCell>{invoice.status}</TableCell>
                  <TableCell>
                    <Button variant="bordered">Download</Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
}
