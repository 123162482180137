import { create } from "zustand";

import {
  ChannelProvider,
  Language,
  SourceContent,
  Status,
} from "@/proivder/channelProvider";
import { SubtitleTrack } from "@/components/videoPlayer";
import { LanguageProvider } from "@/proivder/languageProvider";
import { UserProvider } from "@/proivder/userProvider";
import { BasketItem } from "@/components/Cart";

export interface AudioTrackItem {
  name: string;
  lang: string;
  id: string;
  start: number;
  end: number;
  url: string;
}

interface AudioTrack {
  name: string;
  lang: string;
  id: string;
  main: boolean;
  items: AudioTrackItem[];
}

interface PurchaseDetails {
  plan: string;
  billingCycle: string;
  price: number;
  additionalCredits: number;
  additionalCost: number;
  totalCost: number;
  Sources: number;
  Languages: number;
  Minutes: number;
}

interface HlsLog {
  id: number;
  type: string;
  message: string;
  timestamp: Date;
}

interface AppState {
  getChannels: () => Promise<SourceContent[]>;

  logs: HlsLog[];

  createChannel: (data: Partial<SourceContent>) => Promise<{ _id: number }>;
  updateChannel: (data: Partial<SourceContent>) => Promise<void>;
  addCaptions: (CaptionsOffIcon: SubtitleTrack[]) => void;
  setCurrentTime: (time: number) => void;
  setVideoPlayer: (player: HTMLVideoElement) => void;
  setMultipleSubtitles: (isMultipleSubtitles: boolean) => void;
  getLanguages: () => Promise<void>;

  setVideoBuffer: (buffer: Uint8Array) => void;
  setAudioBuffer: (buffer: Uint8Array) => void;

  cleanBuffers: () => void;

  setCurrentSubtitles: (subtitles: SubtitleTrack[]) => void;

  setChannel: (channel: SourceContent) => void;

  setEditItem: (item: SourceContent | null) => void;

  setChannelTranslations: (translations: string[]) => void;

  reloadVideo: () => void;

  setLoggedIn: (isLoggedIn: boolean) => void;

  getRoles: () => Promise<string[]>;

  setSaveTranscript: (save_transcript: boolean) => void;

  setPurchaseDetails: (purchaseDetails: PurchaseDetails) => void;

  setBasketItems: (basketItems: BasketItem[]) => void;

  basketItems: BasketItem[];

  audioTracks: AudioTrack[];

  channels: SourceContent[];
  captions: SubtitleTrack[];
  currentTime: number;
  videoPlayer?: HTMLVideoElement;

  isMultipleSubtitles: boolean;

  languageProvider: LanguageProvider;

  languages: Language[];

  videoBuffer: Uint8Array[];
  audioBuffer: Uint8Array[];

  currentSubtitles: SubtitleTrack[];

  currentSubtitleIndex?: number;

  channel?: SourceContent;

  editItem: SourceContent | null;

  setLoginRequired: (loginRequired: boolean) => void;

  channelTranslations: string[];

  userRoles: string[];

  loadId?: number;

  userId: string;

  loginRequired: boolean;

  isLoggedin: boolean;

  save_transcript: boolean;

  purchaseDetails: PurchaseDetails;

  setCurrentSubtitleIndex: (index: number) => void;

  setAudioTracks: (audioTracks: AudioTrackItem) => void;

  addLog: (type: string, message: string) => void;
  clearLogs: () => void;
}

/**
 * Custom hook for managing the application state.
 * @returns An object containing the state and methods to manipulate it.
 */
/**
 * Creates and initializes the application store.
 * @param set - A function provided by the state library to update the state.
 * @returns An object representing the application store.
 */
const useAppStore = create<AppState>((set) => ({
  channels: [],
  captions: [],
  currentTime: 0,
  isMultipleSubtitles: true,
  languageProvider: new LanguageProvider(),
  languages: [],
  videoBuffer: [],
  audioBuffer: [],
  loadId: 0,
  userRoles: [],
  audioTracks: [],
  userId: "",
  loginRequired: false,
  basketItems: [],
  logs: [],

  currentSubtitles: [],

  channelTranslations: [],

  editItem: null,
  isLoggedin: false,
  save_transcript: false,

  purchaseDetails: {
    plan: "",
    billingCycle: "",
    price: 0,
    additionalCredits: 0,
    additionalCost: 0,
    totalCost: 0,
    Sources: 0,
    Languages: 0,
    Minutes: 0,
  },

  /**
   * Retrieves the list of channels asynchronously and updates the state.
   * @returns A Promise that resolves when the channels are fetched and the state is updated.
   */
  getChannels: async () => {
    const _channels = localStorage.getItem("channels");

    if (_channels && _channels.length > 0) {
      set((state) => {
        if (state.channels.length === 0) {
          return { channels: JSON.parse(_channels) };
        } else {
          return { channels: state.channels };
        }
      });
    }

    const channels = await ChannelProvider.getChannels();

    channels.content.sort((a, b) => {
      if (
        a.progress > 0 &&
        a.progress < 100 &&
        (b.progress === 0 || b.progress === 100)
      ) {
        return -1; // a comes before b
      } else if (
        (a.progress === 0 || a.progress === 100) &&
        b.progress > 0 &&
        b.progress < 100
      ) {
        return 1; // b comes before a
      } else if (a.status === Status.Queued && b.active) {
        return -1; // a comes before b
      } else if (a.active && b.status === Status.Queued) {
        return 1;
        // b comes before a
      } else if (a.active && !b.active) {
        return -1;
      } else {
        return 0; // no change in order
      }
    });

    set({ channels: [...channels.content] });

    localStorage.setItem("channels", JSON.stringify(channels.content));

    return channels.content;
  },

  /**
   * Creates a new channel asynchronously and updates the state.
   * @param data - The data for creating the channel.
   * @returns A Promise that resolves when the channel is created and the state is updated.
   */
  createChannel: async (data) => {
    const result = await ChannelProvider.createChannel(data);

    return result;
  },

  /**
   * Updates an existing channel asynchronously and updates the state.
   * @param data - The data for updating the channel.
   * @returns A Promise that resolves when the channel is updated and the state is updated.
   */
  updateChannel: async (data) => {
    await ChannelProvider.updateChannel(data);
  },

  /**
   * Sets the captions for the current channel.
   * @param captions - The captions to be set.
   */
  addCaptions: (captions) => {
    set({ captions });
  },

  /**
   * Sets the current time in the player.
   * @param time - The current time to be set.
   */
  setCurrentTime: (time) => {
    set({ currentTime: time });
  },

  setVideoPlayer: (player) => {
    set({ videoPlayer: player });
  },

  setMultipleSubtitles: (isMultipleSubtitles) => {
    set({ isMultipleSubtitles });
  },

  getLanguages: async () => {
    set((state) => {
      if (state.languages.length === 0) {
        ChannelProvider.getLanguages().then((languages) => {
          set({ languages: languages.content });
        });
      }

      return state;
    });
  },

  setVideoBuffer: (buffer) => {
    // check if video buffer is more than 120 seconds then keep only last 120 seconds

    //set({ videoBuffer: buffer });
    set((state) => {
      if (state.videoBuffer.length > 0) {
        const newBuffer = state.videoBuffer.concat(buffer);

        if (newBuffer.length > 10) {
          return { videoBuffer: newBuffer.slice(newBuffer.length - 10) };
        } else {
          return { videoBuffer: newBuffer };
        }
      } else {
        return { videoBuffer: [buffer] };
      }
    });
  },
  setAudioBuffer: (buffer) => {
    set((state) => {
      if (state.audioBuffer.length > 0) {
        const newBuffer = state.audioBuffer.concat(buffer);

        if (newBuffer.length > 10) {
          return { audioBuffer: newBuffer.slice(newBuffer.length - 10) };
        } else {
          return { audioBuffer: newBuffer };
        }
      } else {
        return { audioBuffer: [buffer] };
      }
    });
  },

  cleanBuffers: () => {
    set({ videoBuffer: [], audioBuffer: [] });
  },

  setCurrentSubtitles: (subtitles) => {
    if (subtitles.length === 1) {
      console.trace(subtitles);
    }

    set({ currentSubtitles: subtitles });
  },

  setChannel: (channel) => {
    set({ channel: channel });
  },

  setEditItem: (item) => {
    set({ editItem: item });
  },

  setChannelTranslations: (translations) => {
    set({ channelTranslations: translations });
  },
  reloadVideo: () => {
    set((state) => {
      return { ...state, loadId: state.loadId ? state.loadId + 1 : 1 };
    });
  },

  setLoginRequired: (loginRequired) => {
    set({ loginRequired });
  },

  setLoggedIn: (isLoggedIn) => {
    set({ isLoggedin: isLoggedIn });
  },

  getRoles: async () => {
    const roles = await UserProvider.getRoles();

    set({ userRoles: roles });

    return roles;
  },

  setSaveTranscript: (save_transcript) => {
    set({ save_transcript });
  },

  setCurrentSubtitleIndex: (index) => {
    set({ currentSubtitleIndex: index });
  },

  setAudioTracks: (item) => {
    set((state) => {
      const audioTrack = state.audioTracks.find(
        (track) => track.name === item.name
      );

      if (audioTrack) {
        audioTrack.items.push(item);
      } else {
        state.audioTracks.push({
          name: item.name,
          lang: item.lang,
          id: item.name,
          items: [item],
          main:
            item.name.includes("main") ||
            item.name.toLocaleLowerCase().includes("org"),
        });
      }

      return { audioTracks: state.audioTracks };
    });
  },

  setPurchaseDetails: (purchaseDetails) => {
    set({ purchaseDetails });
  },

  setBasketItems: (basketItems) => {
    set({ basketItems });
  },

  addLog: (type, message) => {
    set((state) => {
      const newLog = {
    
        id: (Math.random() * 100000000) | 0,
        type,
        message,
        timestamp: new Date(),
      };
      let logs = [...state.logs, newLog];

      if (type === 'info') {
        const infoLogs = logs.filter(log => log.type === 'info');
        if (infoLogs.length > 100) {
          const cutoffId = infoLogs[infoLogs.length - 100].id;
          logs = logs.filter(log => !(log.type === 'info' && log.id < cutoffId));
        }
      }

      return { logs };
    });
  },
  clearLogs: () => {
    set({ logs: [] });
  },
}));

export default useAppStore;
