import { Input } from "@nextui-org/input";
import { useToast } from "./ui/use-toast";
import { Button } from "@nextui-org/button";
import { AdminProvider, SlotSettings } from "@/proivder/adminProvider";
import { useEffect, useState } from "react";

export default function ConfigPage() {
  const [config, setConfig] = useState<SlotSettings>({
    total_slots: 0,
    shared_slots: 0,
  });

  const [hls_expiration_time, setHlsExpirationTime] = useState(0);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setConfig((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  async function getSettings() {
    const settings = await AdminProvider.getAdminSettings();
    console.log(settings);
    setConfig(settings.content[0].slot_settings);
    setHlsExpirationTime(settings.content[0].hls_expiration_time.normal_user);
  }

  const { toast } = useToast();

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      setLoading(true);

      await AdminProvider.updateAdminSettings(config, {
        normal_user: hls_expiration_time,
      });

      toast({
        title: "Configuration Updated",
        description: "Your changes have been saved successfully.",
      });

      setLoading(false);
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while updating the configuration.",
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <div className="min-h-screen  py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <h1 className="text-3xl font-bold mb-8">Admin Configuration</h1>
        <form
          onSubmit={handleSubmit}
          className=" shadow-md rounded px-8 pt-6 pb-8 mb-4"
        >
          <div className="mb-6">
            <label htmlFor="maxUsers">Max Slots</label>
            <Input
              id="total_slots"
              name="total_slots"
              type="number"
              value={config.total_slots.toString()}
              onChange={handleInputChange}
              className="mt-1"
              min="1"
            />
          </div>

          <div className="mb-6">
            <label htmlFor="maxUsers">Shared Slots</label>
            <Input
              id="shared_slots"
              name="shared_slots"
              type="number"
              value={config.shared_slots.toString()}
              onChange={handleInputChange}
              className="mt-1"
              min="1"
            />
          </div>

          <div className="mb-6">
            <label htmlFor="maxUsers">HLS Expiration Time</label>
            <Input
              id="hls_expiration_time"
              name="hls_expiration_time"
              type="number"
              value={hls_expiration_time.toString()}
              onChange={(e) => setHlsExpirationTime(parseInt(e.target.value))}
              className="mt-1"
              min="1"
            />
          </div>

          <Button isLoading={loading} type="submit" className="w-full">
            Update Configuration
          </Button>
        </form>
      </div>
    </div>
  );
}
