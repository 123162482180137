import { useState, useEffect } from "react";

import {
  Zap,
  Globe,
  Headphones,
  Mic,
  Plus,
} from "lucide-react";
import { Card, CardBody, CardFooter, CardHeader } from "@nextui-org/card";
import { Radio, RadioGroup, RadioProps } from "@nextui-org/radio";
import { Slider } from "@nextui-org/slider";
import { Badge } from "@nextui-org/badge";
import { Button } from "@nextui-org/button";
import { Accordion, AccordionItem } from "@nextui-org/accordion";
import { Input } from "@nextui-org/input";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/table";
import { cn } from "@nextui-org/theme";

const creditPackages = [
  { credits: 30, price: 30, description: "Perfect for small projects" },
  { credits: 100, price: 95, description: "Great for medium-sized projects" },
  {
    credits: 1000,
    price: 900,
    description: "Ideal for large projects and teams",
  },
];

const creditActions = [
  { credits: 5, action: "Add a source (stream URL)", icon: Plus },
  { credits: 5, action: "Generate transcript subtitles", icon: Zap },
  { credits: 10, action: "Generate translated subtitles", icon: Globe },
  { credits: 10, action: "Generate dubbing for a source", icon: Mic },
  { credits: 15, action: "Create custom voice for dubbing", icon: Headphones },
];

export default function NewPricingPage() {
  const [selectedCredits, setSelectedCredits] = useState(30);
  const [customCredits, setCustomCredits] = useState(30);
  const [discountCode, setDiscountCode] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState(0);
  // const [isDiscountOpen, setIsDiscountOpen] = useState(false);
  // const [currentUserCredits] = useState(50); // Simulated current user credits

  useEffect(() => {
    setCustomCredits(selectedCredits);
  }, [selectedCredits]);

  const handleCreditSelection = (value: string) => {
    const credits = parseInt(value);
    setSelectedCredits(credits);
    setCustomCredits(credits);
  };

  const handleCustomCreditsChange = (value: number | number[]) => {
    const credits = Array.isArray(value) ? value[0] : value;
    setCustomCredits(credits);
    setSelectedCredits(credits);
  };

  const handleDiscountCodeSubmit = () => {
    if (discountCode.toLowerCase() === "save10") {
      setDiscountPercentage(10);
    } else if (discountCode.toLowerCase() === "test") {
      setDiscountPercentage(30);
    } else {
      setDiscountPercentage(0);
    }
  };

  const calculateFinalPrice = () => {
    const basePrice = selectedCredits;
    return (basePrice - (basePrice * discountPercentage) / 100).toFixed(2);
  };

  return (
    <div className="min-h-screen  pb-32 ">
      <div className=" mx-auto pb-32">
        <Card className="mb-8">
          <CardHeader>
            <h1>Select Your Credit Package</h1>
          </CardHeader>
          <CardBody>
            <RadioGroup
              orientation="horizontal"
              defaultValue="30"
              onValueChange={handleCreditSelection}
              className="flex flex-row  gap-4 mb-6"
            >
              {creditPackages.map((pkg) => (
                <CustomRadio
                  key={pkg.credits}
                  value={pkg.credits.toString()}
                  checked={selectedCredits === pkg.credits}
                >
                  <div className=" w-full">
                    <h2 className="text-xl font-bold">{pkg.credits} credits</h2>
                    <p className="text-muted-foreground">{pkg.description}</p>
                  </div>
                  <Badge color="primary">${pkg.price}</Badge>
                </CustomRadio>
              ))}
            </RadioGroup>
            <div className="w-full">
              <label
                htmlFor="credits"
                className="text-lg font-semibold mb-2 block"
              >
                Custom Credits
              </label>
              <div className="flex items-center gap-4">
                <Slider
                  value={[customCredits]}
                  onChange={handleCustomCreditsChange}
                  maxValue={2000}
                  step={10}
                  className="flex-grow"
                />
                <span className="text-lg font-bold w-36 text-right">
                  {customCredits} credits
                </span>
              </div>
            </div>
          </CardBody>
          <CardFooter className="flex justify-between items-center">
            <div className="text-2xl font-bold">
              Total: ${calculateFinalPrice()}
            </div>
            <Button color="primary" size="lg">
              Purchase Credits
            </Button>
          </CardFooter>
        </Card>

<div className=" mb-10">
<Accordion variant="splitted" >
          <AccordionItem title="Have a Discount Code?">
            <div className="flex gap-2">
              <Input
                type="text"
                placeholder="Enter discount code"
                value={discountCode}
                className=" max-w-64"
                onChange={(e) => setDiscountCode(e.target.value)}
              />
              <Button onClick={handleDiscountCodeSubmit}>Apply</Button>
            </div>
            {discountPercentage > 0 && (
              <p className="text-green-600">
                Discount applied: {discountPercentage}% off
              </p>
            )}
          </AccordionItem>
        </Accordion>
</div>

        <Card>
          <CardHeader>
            <h1>What You Can Do With Your Credits</h1>
          </CardHeader>
          <CardBody>
            <Table fullWidth>
              <TableHeader>
                <TableColumn className="w-[100px]">Credits</TableColumn>
                <TableColumn>Action</TableColumn>
                <TableColumn className="text-right">{""}</TableColumn>
              </TableHeader>
              <TableBody>
                {creditActions.map((action, index) => (
                  <TableRow key={index}>
                    <TableCell className="font-medium">
                      {action.credits}
                    </TableCell>
                    <TableCell>{action.action}</TableCell>
                    <TableCell className="text-right">
                      <action.icon className="inline-block w-5 h-5 text-muted-foreground" />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

const CustomRadio = (props: RadioProps) => {
  const { children, ...otherProps } = props;

  return (
    <Radio
      {...otherProps}
      className="w-full "
      classNames={{
        base: cn(
          "inline-flex flex-grow w-full m-0 bg-content1 hover:bg-content2 items-center justify-between",
          "flex-row-reverse w-full  max-md:max-w-full cursor-pointer rounded-lg gap-4 p-4 border-2 border-transparent",
          "data-[selected=true]:border-primary"
        ),
      }}
    >
      {children}
    </Radio>
  );
};
