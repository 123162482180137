import request from "./request";
import { TokenProvider } from "./tokenProvider";

interface UserRequest {
  username: string;
  password: string;
}

interface UserRegisterRequest {
  username: string;
  password: string;
  email: string;
  first_name: string;
  last_name: string;
}

interface UserResponse {
  access_token: string;
  refresh_token: string;
  id_token: string;
  password_is_temporary: boolean;
}

export interface Slot {
  _id: number;
  active: boolean;
  user_id: string;
  source_id: string;
}

export class UserProvider {
  static async login(data: UserRequest, captchaKey: string) {
    try {
      const response = await request.get<UserResponse>("/user/token", {
        params: {
          username: data.username,
          password: data.password,
          "g-recaptcha-response": captchaKey,
        },
      });

      // set token to localstorage
      TokenProvider.setToken(response.data.access_token);
      TokenProvider.setRefreshToken(response.data.refresh_token);

      TokenProvider.setIdToken(response.data.id_token);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async register(data: UserRegisterRequest, captchaKey: string) {
    try {
      const response = await request.post<{ execution: string }>(
        "/user/register",
        null,
        {
          params: {
            username: data.username,
            password: data.password,
            email: data.email,
            first_name: data.first_name,
            last_name: data.last_name,
            "g-recaptcha-response": captchaKey,
          },
        }
      );

      // set token to localstorage
      // TokenProvider.setToken(response.data.access_token);
      // TokenProvider.setRefreshToken(response.data.refresh_token);

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // /emsbt/user/send-verification-code
  static async sendVerificationEmail(execution: string, recaptchaKey: string) {
    try {
      const response = await request.post(
        "/user/send-verification-code",
        null,
        {
          params: {
            execution: execution,
            "g-recaptcha-response": recaptchaKey,
          },
        }
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  static async verifyEmail(execution: string, code: string) {
    try {
      const response = await request.post("/user/verify", null, {
        params: {
          execution: execution,
          code: code,
        },
      });

      return response;
    } catch (error) {
      throw error;
    }
  }

  static async forgetPassword(email: string, captchaKey: string) {
    try {
      const response = await request.post("/user/forget-password", null, {
        params: {
          email: email,
          redirect_url: window.location.origin,
          "g-recaptcha-response": captchaKey,
        },
      });

      return response;
    } catch (error) {
      throw error;
    }
  }

  static async refreshToken() {
    try {
      const response = await request.get<UserResponse>("/user/refresh_token", {
        params: {
          refresh_token: TokenProvider.getRefreshToken(),
        },
      });

      // set token to localstorage
      TokenProvider.setToken(response.data.access_token);
      TokenProvider.setRefreshToken(response.data.refresh_token);

      return response;
    } catch (error) {
      throw error;
    }
  }

  // /config/paid_slots
  static async getAvailableSlots(userId: string) {
    try {
      const response = await request.get<Slot[]>(
        "/config/paid_slots?user_id=" + userId
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }

  static async resendVerificationCode(username: string) {
    // /emsbt/user/resend-verification-code
    const response = await request.post<{ execution: string }>(
      "/user/resend-verification-code",
      null,
      {
        params: {
          username,
        },
      }
    );

    return response.data;
  }

  static async sendTempPassword(email: string, captchaKey: string) {
    // /emsbt/user/send-temp-password
    const response = await request.post<{ execution: string }>(
      "/user/temporary-password",
      null,
      {
        params: {
          email,

          "g-recaptcha-response": captchaKey,
        },
      }
    );

    return response.data;
  }

  static async resetPassword(password: string, captchaKey: string) {
    // /emsbt/user/reset-password
    const response = await request.post("/user/reset-password", null, {
      params: {
        password,
        "g-recaptcha-response": captchaKey,
      },
    });

    return response.data;
  }

  static async getRoles() {
    const response = await request.get<string[]>("/user/roles");

    return response.data;
  }
}
