import { PaymentProvider } from "@/proivder/paymentProvider";
import  { useEffect, useState } from "react";

import { CheckCircle2, XCircle, Home, Loader2 } from "lucide-react";
import { Card, CardBody } from "@nextui-org/card";
import { Button } from "@nextui-org/button";
import { useNavigate } from "react-router-dom";
import DefaultLayout from "@/layouts/default";

export default function HandlePayment() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();

  const sessionID = new URLSearchParams(window.location.search).get(
    "session_id"
  );
  const paymentID = new URLSearchParams(window.location.search).get(
    "payment_id"
  );

  async function handlePaymentSuccess() {
    try {
      setIsLoading(true);
      const response = await PaymentProvider.handlePaymentSuccess(
        paymentID!,
        sessionID!
      );
      setIsLoading(false);
      // check if payment was successful
      setIsSuccess(response.status);
    } catch (error) {
      setIsLoading(false);
      setIsSuccess(false);
      throw error;
    }
  }

  useEffect(() => {
    // log url
    handlePaymentSuccess();
  }, []);

  return (
    <DefaultLayout>
    <div className="min-h-screen bg-gradient-to-br from-primary/20 to-secondary/20 flex items-center justify-center p-4">
      <div className="w-full max-w-md transition-all duration-300 ease-in-out">
        <Card className="w-full">
          <CardBody className="pt-6 px-6 pb-8 flex flex-col items-center text-center">
            {isLoading ? (
              <div className="flex flex-col items-center justify-center h-[200px]">
                <Loader2 className="w-16 h-16 text-primary animate-spin mb-4" />
                <h2 className="text-2xl font-bold mb-2">Processing Payment</h2>
                <p className="text-muted-foreground">
                  Please wait while we confirm your payment...
                </p>
              </div>
            ) : (
              <div className="transition-opacity duration-300 ease-in-out">
                <div className="mb-4 transition-transform duration-300 ease-in-out transform hover:scale-110">
                  {isSuccess ? (
                    <CheckCircle2 className="w-16 h-16 text-green-500 mx-auto" />
                  ) : (
                    <XCircle className="w-16 h-16 text-red-500 mx-auto" />
                  )}
                </div>
                <h2 className="text-2xl font-bold mb-2">
                  {isSuccess ? "Payment Successful" : "Payment Failed"}
                </h2>
                <p className="text-muted-foreground mb-6">
                  {isSuccess
                    ? "Thank you for your payment. Your transaction was successful."
                    : "We're sorry, but there was an issue processing your payment. Please try again."}
                </p>

                <Button
                  onClick={() => navigate("/")}
                  className="w-full transition-colors duration-300 ease-in-out"
                >
                  <Home className="mr-2 h-4 w-4" /> Go to Home Page
                </Button>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden pointer-events-none">
        <div className="absolute top-1/4 left-1/4 w-64 h-64 bg-primary/10 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
        <div className="absolute top-1/3 right-1/4 w-64 h-64 bg-secondary/10 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000" />
        <div className="absolute bottom-1/4 left-1/3 w-64 h-64 bg-accent/10 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
      </div>
    </div>
    </DefaultLayout>
  );
}
