import PaymentProcessing from "@/components/paymentProccessing";
import DefaultLayout from "@/layouts/default";

export default function Payment() {
  return (
    <DefaultLayout>
      {" "}
      <PaymentProcessing onNext={() => {}} />
    </DefaultLayout>
  );
}
