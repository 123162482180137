import Keycloak from "keycloak-js";

import useAppStore from "@/store/store";

export class AuthProvider {
  private static instance: AuthProvider;
  private keycloak: Keycloak;

  private constructor() {
    // constructor logic
    this.keycloak = new Keycloak({
      url: "https://api.ovideo.app/auth",
      realm: "emsbt",
      clientId: "emsbt_web",
    });
  }

  static getInstance(): AuthProvider {
    if (!AuthProvider.instance) {
      const refreshToken = localStorage.getItem("refresh_token");
      const token = localStorage.getItem("token");
      const idToken = localStorage.getItem("id_token");

      AuthProvider.instance = new AuthProvider();
      AuthProvider.instance.keycloak.init({
        onLoad: "check-sso",
        checkLoginIframe: true,
        refreshToken: refreshToken ? refreshToken : undefined,
        token: token ? token : undefined,
        idToken: idToken ? idToken : undefined,

        silentCheckSsoRedirectUri:
          window.location.origin + "/silent-check-sso.html",
      });

      this.instance.refreshToken();
    }

    return AuthProvider.instance;
  }

  async login() {
    // login logic
    await this.keycloak.login({
      // onLoad: "login-required",
      //   checkLoginIframe: false,
      redirectUri: window.location.origin,
    });
  }

  async register() {
    // login logic
    await this.keycloak.register({
      // onLoad: "login-required",
      //   checkLoginIframe: false,
      redirectUri: window.location.origin,
    });
  }

  async logout() {
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("token");
    localStorage.removeItem("id_token");
    this.keycloak.logout({
      redirectUri: window.location.origin,
    });
  }

  get userIsLoggedIn() {
    return this.keycloak.authenticated;
  }

  get token() {
    return this.keycloak.token;
  }

  onAuthentificationChange(callback: (authenticated: boolean) => void) {
    this.keycloak.onAuthLogout = () => callback(false);
    this.keycloak.onAuthSuccess = () => {
      localStorage.setItem("refresh_token", this.keycloak.refreshToken!);
      localStorage.setItem("token", this.keycloak.token!);
      localStorage.setItem("id_token", this.keycloak.idToken!);
      callback(true);
      this.refreshToken();

      useAppStore.setState({ userId: this.keycloak.subject });

      this.getUserRoles();
    };
    this.keycloak.onAuthRefreshSuccess = () => callback(true);

    this.keycloak.onAuthError = () => {
      callback(false);
    };
  }

  setupTokenRefresh() {
    // const refreshInterval = setInterval(() => {
    //   this.keycloak
    //     .updateToken(30) // Refresh if the token will expire in 30 seconds
    //     .then((refreshed) => {
    //       if (refreshed) {
    //         console.log("Token successfully refreshed");
    //       } else {
    //         console.log("Token is still valid");
    //       }
    //     })
    //     .catch(() => {
    //       console.error("Failed to refresh the token, logging out...");
    //       this.keycloak.logout();
    //     });
    // }, 60000); // Check every 60 seconds
    // return () => clearInterval(refreshInterval); // Cleanup on component unmount
  }

  getUserRoles() {
    //this.keycloak.
    // const rules = this.keycloak.tokenParsed?.realm_access?.roles;

    // console.log("rules", rules);

    // // save to zustand app store

    // useAppStore.setState({ userRoles: rules });
    useAppStore.getState().getRoles();
  }

  refreshToken() {
    if (this.keycloak.authenticated) {
      //  return this.keycloak.updateToken(30);
    }
  }
}

// function hashString(str: string) {
//   let hash = 0;

//   for (let i = 0; i < str.length; i++) {
//     const char = str.charCodeAt(i);

//     hash = (hash << 5) - hash + char;
//     hash |= 0; // Convert to 32bit integer
//   }

//   return hash.toString(16); // Convert to hexadecimal string
// }

export function uniqueId() {
  const id = localStorage.getItem("emsbt_device_id");
  if (id) return id;
  // const canvas = document.createElement("canvas");
  // const gl =
  //   canvas.getContext("webgl") || canvas.getContext("experimental-webgl");
  // const userAgent = navigator.userAgent;
  // const screenResolution = `${screen.width}x${screen.height}`;
  // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // const language = navigator.language;
  // const platform = navigator.platform;
  // const plugins = Array.from(navigator.plugins)
  //   .map((p) => p.name)
  //   .join(",");

  // // Generate a WebGL fingerprint
  // const glFingerprint = gl
  //   ? gl.canvas.getContext("webgl")?.getExtension("WEBGL_debug_renderer_info")
  //   : "";

  // // Combine all the components into a single string
  // const fingerprintComponents = [
  //   userAgent,
  //   screenResolution,
  //   timeZone,
  //   language,
  //   platform,
  //   plugins,
  //   glFingerprint,
  // ].join(" | ");

  //

  // generate UUID
  const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }
  );

  localStorage.setItem("emsbt_device_id", uuid);

  // Hash the combined components
  return uuid;
}
