import { useState } from "react";
import { AlertCircle, Info, AlertTriangle, XCircle, Bug } from "lucide-react";
import { Button } from "@nextui-org/button";
import { ScrollShadow } from "@nextui-org/scroll-shadow";
import {
  Modal,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/modal";
import { Tooltip } from "@nextui-org/tooltip";
import { Input } from "@nextui-org/input";

import useAppStore from "@/store/store";

type LogType = "info" | "warning" | "error";

export default function HlsLogger() {
  const logs = useAppStore((state) => state.logs);
  const clearLogs = useAppStore((state) => state.clearLogs);

  const [filter, setFilter] = useState<LogType | "all">("all");

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [search, setSearch] = useState("");

  const filteredLogs =
    search === ""
      ?  filter === "all" ? logs : logs.filter((log) => log.type === filter)
      : logs.filter((log) =>
          log.message.toLowerCase().includes(search.toLowerCase()),
        );

 

  const logTypeIcon = (type: string) => {
    switch (type) {
      case "info":
        return <Info className="w-4 h-4 text-blue-500" />;
      case "warning":
        return <AlertTriangle className="w-4 h-4 text-yellow-500" />;
      case "error":
        return <AlertCircle className="w-4 h-4 text-red-500" />;
    }
  };

  const logTypeColor = (type: string) => {
    switch (type) {
      case "info":
        return "text-blue-700 bg-blue-50";
      case "warning":
        return "text-yellow-700 bg-yellow-50";
      case "error":
        return "text-red-700 bg-red-50";
    }
  };

  return (
    <>
      <Tooltip content="HLS Logs">
        <Button
          isIconOnly
          className=" bg-opacity-40"
          size="sm"
          onClick={() => onOpen()}
        >
          <Bug />
        </Button>
      </Tooltip>
      <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          <ModalHeader>
            <Input
              className="w-full"
              placeholder="Search logs"
              onChange={(e) => setSearch(e.target.value)}
            />
          </ModalHeader>
          <div className="w-full max-w-2xl mx-auto p-4 rounded-lg shadow-lg">
            <div className="flex justify-between items-center mb-4">
             
              <div className="space-x-2">
                <Button
                  color="success"
                  size="sm"
                  variant={filter === "all" ? "solid" : "bordered"}
                  onClick={() => setFilter("all")}
                >
                  All
                </Button>
                <Button
                  color="primary"
                  size="sm"
                  variant={filter === "info" ? "solid" : "bordered"}
                  onClick={() => setFilter("info")}
                >
                  Info
                </Button>
                <Button
                  color="warning"
                  size="sm"
                  variant={filter === "warning" ? "solid" : "bordered"}
                  onClick={() => setFilter("warning")}
                >
                  Warning
                </Button>
                <Button
                  color="danger"
                  size="sm"
                  variant={filter === "error" ? "solid" : "bordered"}
                  onClick={() => setFilter("error")}
                >
                  Error
                </Button>
              </div>
            </div>
            <ScrollShadow className="h-[400px] border rounded-md">
              {filteredLogs.length === 0 ? (
                <div className="flex items-center justify-center h-full ">
                  No logs to display
                </div>
              ) : (
                <ul className="divide-y bg-background">
                  {filteredLogs.map((log) => (
                    <li
                      key={log.id}
                      className={`p-3 ${logTypeColor(log.type)}`}
                    >
                      <div className="flex items-center space-x-2">
                        {logTypeIcon(log.type)}
                        <span className="font-semibold">
                          {log.type.charAt(0).toUpperCase() + log.type.slice(1)}
                        </span>
                        <span className="text-sm ">
                          {log.timestamp.toLocaleTimeString()}
                        </span>
                      </div>
                      <p className="mt-1 text-sm">{log.message}</p>
                    </li>
                  ))}
                </ul>
              )}
            </ScrollShadow>
            <div className="mt-4 flex justify-end">
              <Button color="danger" variant="solid" onClick={clearLogs}>
                <XCircle className="w-4 h-4 mr-2" />
                Clear Logs
              </Button>
            </div>
          </div>
        </ModalContent>
      </Modal>
    </>
  );
}
