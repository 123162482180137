export function base64ToJson(s: string): any {
  try {
    var e: { [key: string]: number } = {},
      i,
      b = 0,
      c,
      x,
      l = 0,
      a,
      r = "",
      w = String.fromCharCode,
      L = s.length;
    var A = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/";
    for (i = 0; i < 64; i++) {
      e[A.charAt(i)] = i;
    }
    for (x = 0; x < L; x++) {
      c = e[s.charAt(x)];
      b = (b << 6) + c;
      l += 6;
      while (l >= 8) {
        ((a = (b >>> (l -= 8)) & 0xff) || x < L - 2) && (r += w(a));
      }
    }

    const regex = /"sub":"([a-z0-9-]+)"/;

    // Use match to extract the value
    const match = r.match(regex);

    if (match && match[1]) {
      const subValue = match[1];
      return subValue;
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }
}
