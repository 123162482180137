import { useEffect, useState } from "react";
import {
  CreditCard,
  Package,
  Settings,
  User,
  Download,
  ShoppingBasket,
  WalletMinimal,
  ArrowLeft,
  LogOut,
} from "lucide-react";

import { useToast } from "@/components/ui/use-toast";
import { Button } from "@nextui-org/button";
import { Divider } from "@nextui-org/divider";
import { Card, CardBody, CardFooter, CardHeader } from "@nextui-org/card";
import { Input } from "@nextui-org/input";
import {
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from "@nextui-org/table";
import { Badge } from "@nextui-org/badge";
import {
  Modal,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/modal";
import { Switch } from "@nextui-org/switch";
import { Progress } from "@nextui-org/progress";
import DefaultLayout from "@/layouts/default";
import CartSection from "@/components/Cart";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthProvider } from "@/proivder/authProvider";

export default function CustomerDashboard() {
  const [activeTab, setActiveTab] = useState("account");
  const { toast } = useToast();

  const location = useLocation();
  const navigate = useNavigate();

  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const isMobile = window.innerWidth < 768;

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text);
    toast({
      title: "Copied to clipboard",
      description: "The output URL has been copied to your clipboard.",
    });
  };

  const SidebarNav = () => (
    <nav className="flex max-lg:overflow-scroll lg:flex-col lg:space-x-0 lg:space-y-1">
      <Button
        variant={activeTab === "account" ? "faded" : "light"}
        className="lg:justify-start"
        size={isMobile ? "sm" : "md"}
        onClick={() => setActiveTab("account")}
      >
        <User className="lg:mr-2 h-4 w-4 max-lg:hidden" />
        Account
      </Button>
      <Button
        variant={activeTab === "outputs" ? "faded" : "light"}
        className="lg:justify-start"
        onClick={() => setActiveTab("outputs")}
        size={isMobile ? "sm" : "md"}
      >
        <Package className="lg:mr-2 h-4 w-4 max-lg:hidden" />
        Outputs
      </Button>
      <Button
        variant={activeTab === "credits" ? "faded" : "light"}
        className="lg:justify-start"
        onClick={() => setActiveTab("credits")}
        size={isMobile ? "sm" : "md"}
      >
        <WalletMinimal className="lg:mr-2 h-4 w-4 max-lg:hidden" />
        Credits
      </Button>
      <Button
        variant={activeTab === "payments" ? "faded" : "light"}
        className="lg:justify-start"
        onClick={() => setActiveTab("payments")}
        size={isMobile ? "sm" : "md"}
      >
        <CreditCard className="lg:mr-2 h-4 w-4 max-lg:hidden" />
        Payments
      </Button>
      <Button
        variant={activeTab === "cart" ? "faded" : "light"}
        className="lg:justify-start"
        onClick={() => setActiveTab("cart")}
        size={isMobile ? "sm" : "md"}
      >
        <ShoppingBasket className="lg:mr-2 h-4 w-4 max-lg:hidden" />
        Cart
      </Button>

      <Divider className=" mt-4" />
      <Button
        variant={"light"}
        className="lg:justify-start"
        size={isMobile ? "sm" : "md"}
        onClick={() => {
          const authProvider = AuthProvider.getInstance();
          authProvider.logout();

          navigate("/");
        }}
      >
        <LogOut className="lg:mr-2 h-4 w-4 max-lg:hidden" />
        Logout
      </Button>
    </nav>
  );

  useEffect(() => {
    // check if not logged in then redirect to home (token)
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }

    const handleTabChange = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const tab = urlParams.get("tab");
      if (tab) {
        setActiveTab(tab);
      }
    };
    handleTabChange();
  }, [location]);

  useEffect(() => {
    const urlParams = new URLSearchParams();
    urlParams.set("tab", activeTab);
    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}?${urlParams.toString()}`
    );
  }, [activeTab]);

  return (
    <DefaultLayout>
      <div className=" settings space-y-6  md:block">
        <div className="space-y-0.5 flex gap-4 items-center">
          <Button
            onClick={() => navigate("/")}
            color="default"
            size="sm"
            isIconOnly
          >
            {" "}
            <ArrowLeft />{" "}
          </Button>
          {/* <p className="text-muted-foreground">
            Manage your account settings and set e-mail preferences.
          </p> */}
          <h2 className="text-xl"> Settings </h2>
        </div>
        <Divider className="my-3" />
        <div className="flex flex-col space-y-4 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="lg:w-1/5">
            <SidebarNav />
          </aside>
          <div className="flex-1 lg:max-w-2xl">
            {activeTab === "account" && (
              <div className="space-y-6">
                <Card>
                  <CardHeader>
                    <h2>Personal Information</h2>
                    {/* <CardDescription>Update your account details here.</CardDescription> */}
                  </CardHeader>
                  <CardBody className="space-y-4">
                    <div className="space-y-2">
                      <label htmlFor="email">Email</label>
                      <Input id="email" value="user@example.com" readOnly />
                    </div>
                    <div className="space-y-2">
                      <label htmlFor="username">Username</label>
                      <Input id="username" defaultValue="johndoe" />
                    </div>
                    <div className="grid gap-4 md:grid-cols-2">
                      <div className="space-y-2">
                        <label htmlFor="name">Name</label>
                        <Input id="name" defaultValue="John" />
                      </div>
                      <div className="space-y-2">
                        <label htmlFor="family">Family</label>
                        <Input id="family" defaultValue="Doe" />
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button size="sm" color="primary">
                      Save Changes
                    </Button>
                  </CardFooter>
                </Card>

                <Card>
                  <CardHeader className="flex flex-col gap-2 items-start">
                    <h2 className="text-xl font-semibold">Change Password</h2>
                    <p className="text-xs">
                      Ensure your account is using a long, random password to
                      stay secure.
                    </p>
                  </CardHeader>
                  <CardBody className="space-y-4">
                    <div className="space-y-2">
                      <label htmlFor="current-password">Current Password</label>
                      <Input
                        size="sm"
                        className=" max-w-md"
                        id="current-password"
                        type="password"
                      />
                    </div>
                    <div className="space-y-2">
                      <label htmlFor="new-password">New Password</label>
                      <Input
                        className=" max-w-md"
                        size="sm"
                        id="new-password"
                        type="password"
                      />
                    </div>
                    <div className="space-y-2">
                      <label htmlFor="repeat-password">
                        Confirm New Password
                      </label>
                      <Input
                        size="sm"
                        className=" max-w-md"
                        id="repeat-password"
                        type="password"
                      />
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button size="sm" color="primary">
                      Change Password
                    </Button>
                  </CardFooter>
                </Card>
              </div>
            )}

            {activeTab === "outputs" && (
              <div className="space-y-6">
                <Card>
                  <CardHeader>
                    <h2>Outputs Overview</h2>
                    {/* <CardDescription>Summary of your current outputs</CardDescription> */}
                  </CardHeader>
                  <CardBody>
                    <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-4">
                      <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                          <h2 className="text-sm font-medium">Total Outputs</h2>
                          <Package className="h-4 w-4 text-muted-foreground" />
                        </CardHeader>
                        <CardBody>
                          <div className="text-2xl font-bold">10</div>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                          <h2 className="text-sm font-medium">
                            Active Outputs
                          </h2>
                          <Package className="h-4 w-4 text-muted-foreground" />
                        </CardHeader>
                        <CardBody>
                          <div className="text-2xl font-bold">8</div>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                          <h2 className="text-sm font-medium">Total Viewers</h2>
                          <User className="h-4 w-4 text-muted-foreground" />
                        </CardHeader>
                        <CardBody>
                          <div className="text-2xl font-bold">1,234</div>
                        </CardBody>
                      </Card>
                      <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                          <h2 className="text-sm font-medium">
                            Total Bandwidth
                          </h2>
                          <Settings className="h-4 w-4 text-muted-foreground" />
                        </CardHeader>
                        <CardBody>
                          <div className="text-2xl font-bold">1.2 TB</div>
                        </CardBody>
                      </Card>
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <h2>Output Streams</h2>
                    {/* <CardDescription>Manage your output streams here.</CardDescription> */}
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <TableHeader>
                        <TableColumn>Name</TableColumn>
                        <TableColumn>Status</TableColumn>
                        <TableColumn>Viewers</TableColumn>
                        <TableColumn>Bandwidth</TableColumn>
                        <TableColumn>Actions</TableColumn>
                      </TableHeader>
                      <TableBody>
                        {[
                          {
                            name: "ABC",
                            status: "Public",
                            viewers: 500,
                            bandwidth: "50 GB",
                          },
                          {
                            name: "Al-Jazeera",
                            status: "Private",
                            viewers: 200,
                            bandwidth: "20 GB",
                          },
                          {
                            name: "Sky News",
                            status: "Public",
                            viewers: 750,
                            bandwidth: "75 GB",
                          },
                          {
                            name: "CNN",
                            status: "Private",
                            viewers: 300,
                            bandwidth: "30 GB",
                          },
                          {
                            name: "BBC",
                            status: "Public",
                            viewers: 1000,
                            bandwidth: "100 GB",
                          },
                        ].map((stream) => (
                          <TableRow key={stream.name}>
                            <TableCell className="font-medium">
                              {stream.name}
                            </TableCell>
                            <TableCell>
                              <Badge
                                variant={
                                  stream.status === "Public" ? "solid" : "faded"
                                }
                              >
                                {stream.status}
                              </Badge>
                            </TableCell>
                            <TableCell>{stream.viewers}</TableCell>
                            <TableCell>{stream.bandwidth}</TableCell>
                            <TableCell>
                              <div className="flex items-center space-x-2">
                                <Button
                                  onPress={onOpen}
                                  variant="bordered"
                                  size="sm"
                                >
                                  View URL
                                </Button>
                                <Modal
                                  isOpen={isOpen}
                                  onOpenChange={onOpenChange}
                                >
                                  <ModalContent className="sm:max-w-md">
                                    <ModalHeader>
                                      <h2>Output URL</h2>
                                      <p>
                                        Click the URL to copy it to your
                                        clipboard.
                                      </p>
                                    </ModalHeader>
                                    <div className="flex items-center space-x-2">
                                      <Input
                                        readOnly
                                        value={`https://stream.example.com/${stream.name.toLowerCase()}`}
                                        onClick={() =>
                                          copyToClipboard(
                                            `https://stream.example.com/${stream.name.toLowerCase()}`
                                          )
                                        }
                                      />
                                    </div>
                                  </ModalContent>
                                </Modal>
                                <Switch />
                                <Button color="danger" size="sm">
                                  Delete
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                </Card>
              </div>
            )}

            {activeTab === "credits" && (
              <div className="space-y-6">
                <Card>
                  <CardHeader className=" flex flex-col gap-2 items-start">
                    <h2 className="text-xl font-semibold">Credit Balance</h2>
                    <p className="text-sm">
                      Your current credit balance and usage
                    </p>
                  </CardHeader>
                  <CardBody>
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-2xl font-bold">546 Credits</p>
                        <p className="text-sm text-muted-foreground">
                          500 credits used this month
                        </p>
                      </div>
                      <Button
                        onClick={() => {
                          setActiveTab("cart");
                        }}
                      >
                        Add Credits
                      </Button>
                    </div>
                    <div className="mt-4 space-y-2">
                      <div className="flex items-center justify-between text-sm">
                        <span>Monthly Usage</span>
                        <span>50%</span>
                      </div>
                      <Progress value={50} />
                    </div>
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader className=" flex flex-col items-start gap-2">
                    <h2 className=" text-xl font-semibold">Credits History</h2>
                    <p className=" text-sm">Your recent credit transactions</p>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <TableHeader>
                        <TableColumn>Date</TableColumn>
                        <TableColumn>Type</TableColumn>
                        <TableColumn>Details</TableColumn>
                        <TableColumn className="text-right">Amount</TableColumn>
                      </TableHeader>
                      <TableBody>
                        {[
                          {
                            date: "2023-06-15",
                            type: "Purchase",
                            amount: 500,
                            details: "Credit purchase",
                          },
                          {
                            date: "2023-06-14",
                            type: "Usage",
                            amount: -50,
                            details: "Add Source (CNN)",
                          },
                          {
                            date: "2023-06-13",
                            type: "Usage",
                            amount: -30,
                            details: "Add translation (Spanish, BBC)",
                          },
                          {
                            date: "2023-06-12",
                            type: "Usage",
                            amount: -20,
                            details: "Add transcript (Sky News)",
                          },
                          {
                            date: "2023-06-11",
                            type: "Usage",
                            amount: -40,
                            details: "Add dubbing (French, Al-Jazeera)",
                          },
                        ].map((transaction, index) => (
                          <TableRow key={index}>
                            <TableCell>{transaction.date}</TableCell>
                            <TableCell>{transaction.type}</TableCell>
                            <TableCell>{transaction.details}</TableCell>
                            <TableCell
                              className={`text-right ${transaction.amount > 0 ? "text-green-600" : "text-red-600"}`}
                            >
                              {transaction.amount > 0 ? "+" : ""}
                              {transaction.amount}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                </Card>
              </div>
            )}

            {activeTab === "payments" && (
              <div className="space-y-6">
                <Card>
                  <CardHeader>
                    <h2>Latest Payment</h2>
                    {/* <CardDescription>Your most recent transaction</CardDescription> */}
                  </CardHeader>
                  <CardBody>
                    <div className="space-y-2">
                      <div className="flex justify-between">
                        <span className="font-medium">Amount:</span>
                        <span>$50.00</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="font-medium">Date:</span>
                        <span>June 15, 2023</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="font-medium">Payment Method:</span>
                        <span>Credit Card (****1234)</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="font-medium">Status:</span>
                        <Badge>Success</Badge>
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button variant="bordered" className="w-full">
                      <Download className="mr-2 h-4 w-4" />
                      Download Receipt
                    </Button>
                  </CardFooter>
                </Card>

                <Card>
                  <CardHeader>
                    <h2>Payment History</h2>
                    {/* <CardDescription>Your recent payments and invoices</CardDescription> */}
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <TableHeader>
                        <TableColumn>Date</TableColumn>
                        <TableColumn>Amount</TableColumn>
                        <TableColumn>Credits</TableColumn>
                        <TableColumn>Method</TableColumn>
                        <TableColumn>Status</TableColumn>
                        <TableColumn>Actions</TableColumn>
                      </TableHeader>
                      <TableBody>
                        {[
                          {
                            date: "2023-06-15",
                            amount: 50,
                            credits: 1000,
                            method: "Credit Card",
                            status: "Success",
                          },
                          {
                            date: "2023-06-05",
                            amount: 100,
                            credits: 2000,
                            method: "PayPal",
                            status: "Success",
                          },
                          {
                            date: "2023-05-20",
                            amount: 75,
                            credits: 1500,
                            method: "Bank Transfer",
                            status: "Pending",
                          },
                          {
                            date: "2023-05-10",
                            amount: 25,
                            credits: 500,
                            method: "Credit Card",
                            status: "Failed",
                          },
                          {
                            date: "2023-05-01",
                            amount: 60,
                            credits: 1200,
                            method: "PayPal",
                            status: "Success",
                          },
                        ].map((payment, index) => (
                          <TableRow key={index}>
                            <TableCell>{payment.date}</TableCell>
                            <TableCell>${payment.amount.toFixed(2)}</TableCell>
                            <TableCell>{payment.credits}</TableCell>
                            <TableCell>{payment.method}</TableCell>
                            <TableCell>
                              <Badge
                                color={
                                  payment.status === "Success"
                                    ? "success"
                                    : payment.status === "Pending"
                                      ? "warning"
                                      : "danger"
                                }
                              >
                                {payment.status}
                              </Badge>
                            </TableCell>
                            <TableCell>
                              {payment.status === "Success" ? (
                                <Button variant="bordered" size="sm">
                                  <Download className="mr-2 h-4 w-4" />
                                  Invoice
                                </Button>
                              ) : payment.status === "Pending" ? (
                                <Button size="sm">Pay Now</Button>
                              ) : null}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </CardBody>
                </Card>
              </div>
            )}

            {activeTab === "cart" && (
              <div className="space-y-6">
                <CartSection />
              </div>
            )}
          </div>
        </div>
      </div>
    </DefaultLayout>
  );
}
