import { useEffect, useRef, useState } from "react";
import { ScrollShadow } from "@nextui-org/scroll-shadow";

import DefaultLayout from "@/layouts/default";
import VideoPlayer from "@/components/videoPlayer";
import useAppStore from "@/store/store";
import {
  ChannelProvider,
  SourceContent,
  Status,
} from "@/proivder/channelProvider";
import Confirm from "@/components/confirm";
import { useToast } from "@/components/ui/use-toast";
import StreamList from "@/components/streamList";
export default function IndexPage() {
  const getChannels = useAppStore((state) => state.getChannels);
  // const [channel, setChannel] = useState<Channel | null>(null);
  const channels = useAppStore((state) => state.channels);

  const [, setUpdateSource] = useState<SourceContent | null>(null);
  const [deleteSource, setDeleteSource] = useState<SourceContent | null>(null);

  const [sortedChannels, setSortedChannels] = useState<SourceContent[]>([]);

  const videoPlayer = useAppStore((state) => state.videoPlayer);

  const setChannelToStore = useAppStore((v) => v.setChannel);
  const channel = useAppStore((v) => v.channel);

  const setEditItem = useAppStore((v) => v.setEditItem);

  const scrollRef = useRef<HTMLDivElement>(null);

  const { toast } = useToast();

  const [_, setIsReady] = useState(false);

  const getLanguages = useAppStore((state) => state.getLanguages);

  async function handleDeleteSource(confirm: boolean) {
    if (confirm && deleteSource) {
      await ChannelProvider.deleteChannel(deleteSource._id);
      toast({
        title: "Deleted",
        description: "Channel has been deleted successfully",
        duration: 5000,
      });
    } else {
      setDeleteSource(null);
    }
  }

  function handleUpdateSource(channel: SourceContent | null) {
    setEditItem(channel);
    setUpdateSource(channel);
  }

  async function init() {
    document.body.classList.add("home");

    const oldStorage = localStorage.getItem("isMultipleSubtiles");

    if (oldStorage) {
      localStorage.clear();
    }
    getLanguages();
    const savedLanguages = localStorage.getItem("selected_languages");

    if (!savedLanguages) {
      localStorage.setItem(
        "selected_languages",
        JSON.stringify(["en", "tr", "ar"])
      );
    }

    const channels = await getChannels();

    handlePlayLastVideoWhenOpenApp(channels);

    setInterval(() => {
      getChannels();
    }, 1500);
  }

  useEffect(() => {
    init();

    return () => {
      document.body.classList.remove("home");
    };
  }, []);

  // useEffect(() => {
  //   if (channels.content.length)
  // , [channels]);

  useEffect(() => {
    // if (channels.content) {
    if (channels && channels.length) {
      setSortedChannels([
        ...channels.slice().filter((_channel) => _channel._id !== channel?._id),
      ]);

      setTimeout(() => {
        setSortedChannels([
          ...channels.slice().sort((a, _) => {
            if (
              a._id === channel?._id ||
              a?.status === Status.Queued ||
              a?.status === Status.Initializing ||
              (a.progress > 0 && a.progress < 100)
            ) {
              return -1;
            } else {
              return 1;
            }
          }),
        ]);
      }, 300);
    }
  }, [channel]);

  useEffect(() => {
    // if (channels.content) {
    if (channels && channels.length) {
      setSortedChannels([
        ...channels.slice().sort((a, _) => {
          if (a._id === channel?._id) {
            return -1;
          } else {
            return 1;
          }
        }),
      ]);
    }
  }, [channels]);

  // const stringToArrayBuffer = (str: string): ArrayBuffer => {
  //   const encoder = new TextEncoder();
  //   const uint8Array = encoder.encode(str);
  //   return uint8Array.buffer;
  // };

  function handlePlayLastVideoWhenOpenApp(channels: SourceContent[] = []) {
    const lastVideoId = localStorage.getItem("lastVideoId");

    if (lastVideoId) {
      const lastVideo = channels.find(
        (item) => item._id == parseInt(lastVideoId)
      );

      if (
        lastVideo &&
        lastVideo.status === Status.Recording &&
        lastVideo.active
      ) {
        setChannelToStore(lastVideo);

        return;
      }
    }

    const firstChannel = channels.find(
      (item) => item.status === Status.Recording && item.active
    );

    if (firstChannel) {
      setChannelToStore(firstChannel);

      return;
    }

    setIsReady(true);
  }

  return (
    <DefaultLayout>
      <section className="flex flex-col items-center  h-[calc(100vh-50px)]">
        <div className="flex flex-col gap-1 w-full" />

        <div className="flex w-full flex-wrap md:flex-nowrap gap-4">
          <VideoPlayer
            channel={channel!}
            onDelete={setDeleteSource}
            onExport={() => null}
            onUpdate={handleUpdateSource}
            channels={channels}
            onReady={() => {
              setIsReady(true);
            }}
          />
        </div>

        <ScrollShadow
          ref={scrollRef}
          className=" flex-grow overflow-scroll w-full lg:min-h-[350px]"
        >
          <StreamList
            channel={channel!}
            items={sortedChannels}
            videoPlayer={videoPlayer!}
            onChannel={(channel) => {
              //setChannel(channel);
              setChannelToStore(channel);
            }}
            onDelete={setDeleteSource}
            onExport={() => null}
            onPlay={() => {
              scrollRef.current?.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            onUpdate={handleUpdateSource}
          />
        </ScrollShadow>

        <Confirm
          title="Are you sure to delete this channel?"
          toggle={!!deleteSource}
          onConfirm={(val) => handleDeleteSource(val)}
        />
      </section>
      {/* <LoadingModal open={!isReady} /> */}
    </DefaultLayout>
  );
}
