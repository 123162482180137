import request from "./request";

export interface SlotSettings {
  total_slots: number;
  shared_slots: number;
}

export interface AdminSettings {
  success: boolean;
  content: {
    _id: string;
    hls_expiration_time: HlsExpirationTime;
    slot_settings: SlotSettings;
  }[];
}

interface HlsExpirationTime {
    normal_user: number;
}

export class AdminProvider {
  // get admin settings
  static async getAdminSettings() {
    try {
      const response = await request.get<AdminSettings>("/admin/settings");
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // update admin settings
  static async updateAdminSettings(
    data: SlotSettings,
    hls_expiration_time: HlsExpirationTime
  ) {
    try {
      const response = await request.put<AdminSettings>("/admin/settings", {
        slot_settings: data,
        hls_expiration_time,

        _id: "admin_settings",
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}
